.storia-main-container {
    display: flex;
    width: 100vw;
    background-color: #e9e6c9;
}

.storia-content-container {
    padding-left: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
}

.storia-title {
    font-size: 7rem;
    margin: 0;
    color:#13220d;
}

.storia-subtitle {
    font-size: 1.5rem;
    margin: 0;
}

.storia-action-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    gap: 10px;
    color: #13220d;
    cursor: pointer;
}

.storia-action-container:hover {
    text-decoration: underline;
}

.storia-action-text {
    font-weight: bold;
}

.storia-action-text:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .storia-content-container {
        width: 90%;
    }

    .storia-title {
        font-size: 4rem;

    }

    .storia-subtitle {
        font-size: 1rem;

    }
}