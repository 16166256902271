.mainContainer {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-image: url("/public/images/vigna.jpg");
    background-size: cover;
}

.contentContainer {
    padding-left: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;

    background: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 25%, rgba(0, 0, 0, 0) 50%);
}

.title {
    font-size: 7rem;
    margin: 0;
    color:#dbd5a5;
}

.subtitle {
    font-size: 1.5rem;
    margin: 0;
    color:#fff;
    width: 50%;
}

.actionContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    gap: 10px;
    color: #fff;
    cursor: pointer;
}

.actionContainer:hover {
    text-decoration: underline;
}

.actionText {
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .contentContainer {
        width: 100%;
    }

    .title {
        font-size: 4rem;
    }

    .subtitle {
        font-size: 1rem;
        margin: 0;
        color:#fff;
        width: 90%;
    }
}
