.mainContainer {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.imageContainer {
    width: 100%;
    min-height: 55vh;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url("../../public/images/pratiche/pratiche-img1.JPG");
    background-size: cover;
    background-position: center;

    position: relative;

}

.backdrop {
    position: absolute;
    height: 100%;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.42);

    z-index: 1;
}

.imageText {
    width: 60%;
    font-size: 2rem;
    text-align: center;
    color: #fff;
    font-weight: normal;
    /*text-shadow: 3px 3px 3px #1c1c1c;*/

    overflow: hidden;

    z-index: 2;
}

.contentContainer {
    width: 100%;
    min-height: 50vh;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

.contentText {
    width: 60%;
    font-size: 1.5rem;
    text-align: center;
    color: #000;
    font-weight: normal;
}

@media only screen and (max-width: 600px) {
    .imageText {
        width: 90%;
        font-size: 1.1rem;
    }

    .contentText {
        width: 80%;
        font-size: 1rem;
        text-align: center;
        color: #000;
        font-weight: normal;
        margin-top: 0;
    }
}