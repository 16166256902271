.main-container {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.top-container {
    width: 90%;
    margin-top: 3%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 10px;
    width: fit-content;
}

.name-container {
    margin-left: 5%;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
}

.title {
    font-size: 3vw;
    font-weight: 800;
}

.logo {
    width: 11vh;
    height: 11vh;
}

.arrow-icon {
    color:black;
}

.name {
    font-size: 7vw;
}

.definizione {
    text-align: justify;
    font-family: 'Sentient', serif;
}

.definizione-open {
    animation: 0.8s linear 0s move_definition;
}

.definizione-closed {
    opacity: 0%;
    animation: 0.8s linear 0s move_definition_inverted;
}

.definizione-container {
    margin-left: 5%;
    width: 50%;
    height: 30vh;
}

.rotate-180 {
    transform: rotate(180deg);
    display: inline-block;
    position: relative;
    animation: 0.3s linear 0s rotate_180;
}

.rotate-180-inverted {
   transform: rotate(0deg);
   display: inline-block;
   position: relative;
   animation: 0.3s linear 0s rotate_180_inverted;
}

@keyframes rotate_180 {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

@keyframes rotate_180_inverted {
    0% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes move_definition {
    0% {
        opacity: 0;
        transform: translateY(-30%);
    }
    25% {
        transform: translateY(0%);
        opacity: 30%;
    }
    35% {
        opacity: 40%;
    }
    100% {
        opacity: 100%;
        transform: translateY(0%);
    }
}

@keyframes move_definition_inverted {
    0% {
        opacity: 100;
        transform: translateY(0%);
    }
    25% {
        opacity: 40%;
        transform: translateY(-30%);
    }
    35% {
        opacity: 0%;
        transform: translateY(-30%);
    }
    100% {
        opacity: 0%;
        transform: translateY(-30%);
    }
}

@media only screen and (max-width: 600px) {
    .logo {
        width: 8vh;
        height: 8vh;
    }

    .name {
        font-size: 20vw;
    }

    .name-container {
        height: 50vh;
    }

    .title {
        font-size: 8vw;
        font-weight: 800;
    }

    .definizione-container {
        margin-left: 5%;
        width: 80%;
        height: 50vh;
    }
}

