

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    background-color: #dbd5a5;

    position: relative;
}

.innerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 100vh;
}

.iframe {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    border: none;
}

.textContainer {
    width: 50%;
    padding-left: 5%;
    justify-self: flex-start;
    align-self: flex-start;
    font-size: 18px;
}

.title {
    font-size:4rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding-left: 5%;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .container {
        height: fit-content;
        min-height: 100vh;
    }
    .innerContainer {
        flex-direction: column;
    }

    .title {
        padding-top: 10%;
        padding-left: 10%;
    }

    .textContainer {
        width: 80%;
        font-size: 16px;
        padding-left: 10%;
    }

    .title {
        font-size: 2rem;
        margin-bottom: 0px;
        margin-top: 1vh;
    }

    .iframe {
        height: 40vh;
    }
}