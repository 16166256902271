.bottleImg {
    height: 50vh;
    margin-bottom: 0px;
}

.recycleIcon {
    width: 50px;
}

.labelContainer {
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: 2vh;
}

.tableContainer {
    display: flex;
    justify-content: center;
    overflow: hidden;
    text-align: center;
}

.infoContainer {
    justify-content: center;
    overflow: hidden;
    text-align: center;
}

.mainContainer {
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
}

.styledTable {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    text-align: center;
    font-weight: bold;
}

.styledTable thead tr {
    background-color: #DBD5A5;
    color: black;
    text-align: center;
}

.styledTable th, .styledTable td {
    padding: 12px 15px;
}

.styledTable tbody tr {
    border-bottom: 1px solid #dddddd;
}
