.mainContainer {
    display: flex;
    width: 100vw;
    flex-direction: row;
    background-color: #dbd5a5;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 5%;

}

.contentContainer {
    padding-left: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.carouselContainer {
    width: 40%;
    height: auto;
}

.title {
    font-size: 4rem;
    font-weight: bold;
    margin: 0;
    color: #13220d;
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
}

.innerTitle {
    text-align: left;
    font-weight: normal;
    color: #13220d;
    font-size: 1.4rem;
}

.description {
    text-align: left;
    font-weight: normal;
    font-size: 1.2rem;
    color: #13220d;
    width: 100%;
}

.action {
    text-align: left;
    font-weight: bold;
    font-size: 1.5rem;
    color: #13220d;
    width: 100%;
}

.image {
    width: 45%;
}

@media only screen and (max-width: 600px) {
    .productContainer {
        flex-direction: column;
    }
    .title {
        font-size: 3rem;
    }
    .description {
        font-size: 1rem;
        margin-top: 0;
    }
    .innerTitle {
        font-size: 1rem;
    }
    .action {
        font-size: 1.2rem;
        margin-top: 0;
    }
    .textContainer {

        width: 90%;
    }

    .rowContainer {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .carouselContainer {
        width: 90%;
        height: auto;
    }

}
