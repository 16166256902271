.mainContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: #dbd5a5;
    height: 100%;

}

.content {

    width: 60%;
}

.imageContainer {
    width: 100%;
    height: 100vh;
}

.image {
    width:auto;
    height: 100vh;
}

@media only screen and (max-width: 600px) {
    .mainContainer {
        width: 100vw;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .content {
        width: 90%;
    }

    .backArrow {
        top:10px !important;
        left: 5px !important;
    }

    .backArrowImg {
        font-size: 1.5rem !important;
    }
}