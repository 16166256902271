.st-page-main-container {
    display: flex;
    flex-direction: column;

    overflow: hidden;
}

.st-page-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}

.st-page-text-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding-top: 5%;
}

.st-page-title {
    font-size: 4.5rem;
    margin: 0;
}

.st-page-text {
    font-size: 1.3rem;
    width: 90%;
}

.st-page-img-1 {
    margin:auto;
    width: 600px;
    overflow: hidden;
    border-radius: 20px;
}

.st-page-img-2 {
    margin:auto;
    position:absolute;
    width: auto;
    height: 100vh;
    left: 0;
    top: 100vh;
    overflow: hidden;
}

.st-page-img-1-container {
    width: 50%;
    display: flex;
    justify-content: center;
}

.st-page-img-2-container {
    width: 50%;
}

.st-page-img-3 {
    width: 60%;
    border-radius: 20px;
}

@media only screen and (max-width: 600px) {
    .st-page-text-container {
        padding-top: 10%;
    }

    .st-page-back {
        font-size: 1.5rem;
    }

    .st-page-title {
        font-size: 2rem;
        margin: 0;
    }

    .st-page-text {
        font-size: 2.3vh;
        width: 95%;

    }

    .st-page-img-1 {
        width: 300px;
        margin: auto;
    }

    .st-page-img-2 {
        width: 50% !important;
        height: auto;
        margin: auto;
    }

    .st-page-img-2 {
        position: relative;
        margin:auto;
        width: auto;
        height: auto;
        left: unset;
        top: unset;
    }

    .st-page-img-1-container {
        width: 100%;
        height: 40%;
    }



    .st-page-container {
        flex-direction: column;
    }

    .st-page-text-container {
        width: 80%;
        padding-left: 10%;
    }
}