.mainContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #13220d;
    gap: 20vw;

    width: 100vw;
    height: 100vh;
}

.textContainer {
    display: flex;
    flex-direction: column;

    color:#ffffff;
}

.logo {
    filter: brightness(0) invert(1);
    width: 30%;
}

.title {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 0;
}

.description {
    font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
    .mainContainer {
        flex-direction: column;
    }

    .logo {
        width: 50%;
    }

    .title {
        font-size: 2rem;
        text-align: center;
    }

    .description {
        font-size: 1rem;
    }
}