.bannerContainer {
    width: 100vw;
    overflow: hidden;
    background-color: #dbd5a5;
    cursor: pointer;
    position: relative;
}

.marqueeContent {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 200s linear infinite;
}

.marqueeItem {
    display: inline-block;
    margin: 0 2rem;
    color: #13220d;
    font-size: 3rem;
    font-weight: bold;
    padding: 10px;
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
