.mainContainer {
    display: flex;
    width: 100vw;
    min-height: 100vh;
    flex-direction: row;

}

.productContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.contentContainer {
    padding-left: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
}

.title {
    font-size: 4rem;
    font-weight: bold;
    margin: 0;
    color:#000;

    padding-top: 5%;
}

.productContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.productTitle {
    text-align: right;
    font-weight: bold;
    font-size: 2rem;
}

.productDescription {
    text-align: right;
    font-weight: normal;
    font-size: 1.4rem;
    width: 80%;
}

.productImage {
    width: 45%;
}

@media only screen and (max-width: 600px) {
    .productContainer {
        flex-direction: column;
    }

    .title {
        font-size: 3rem;
    }

    .productTitle {
        text-align: left;
        font-size: 1.6rem;
        margin-bottom: 0;
    }

    .productDescription {
        text-align: left;
        font-size: 1rem;
        width: 90%;
        margin-bottom: 0;
        margin-top: 5px;
    }

    .productContentContainer {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .productImage {
        width: 85%;
    }
}
